<template>
  <ConfigPage>
    <ConfigItem
      :title="__('Distribuição automática', 'settings')"
      :description="
        __(
          'O parâmetro define se o sistema deve sugerir automaticamente a unidade quando a lead é criada',
          'settings'
        )
      "
    >
      <v-card class="mx-4" outlined>
        <v-card-text>
          <v-switch
            v-model="value"
            :label="__('Ativar distribuição automática', 'settings')"
            @change="handleUpdateEnabled"
          ></v-switch>
        </v-card-text>
      </v-card>
    </ConfigItem>
  </ConfigPage>
</template>

<script>
import ConfigPage from "@/layouts/configs/ConfigPage";
import ConfigItem from "@/layouts/configs/ConfigItem";
import i18n from "@/mixins/i18n";
import { getParam, updateParam } from "@/services/configs/system_params";

export default {
  mixins: [i18n],
  components: {
    ConfigPage,
    ConfigItem,
  },
  data() {
    return {
      value: false,
      loading: false,
    };
  },
  methods: {
    async handleUpdateEnabled(value) {
      try {
        await this.updateParam(value);
        this.$store.commit("alert", "Salvo!");
      } catch (error) {
        this.$store.commit("error", "Não foi possível atualizar parâmetro");
        this.value = !value;
      }
    },
    async loadParam() {
      this.loading = true;
      try {
        const { value } = await getParam("lead_distribution_active");
        this.value = value;
      } catch (error) {
        this.$store.commit("error", "Não foi possível carregar parâmetro");
      } finally {
        this.loading = false;
      }
    },
    updateParam(value) {
      return updateParam("lead_distribution_active", value);
    },
  },
  created() {
    this.loadParam();
  },
};
</script>
